//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Invoices from '~/views/MerchantsInvoices/Invoices/Table.vue';
import InvoicesTransactions from '~/views/MerchantsInvoices/InvoicesTransactions/Table.vue';

export default {
  components: {
    Invoices,
    InvoicesTransactions,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadInvoices(),
      this.loadInvoicesTransactions(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('merchantsInvoices', {
      loadInvoices: 'loadData',
    }),
    ...mapActions('merchantsInvoicesTransactions', {
      loadInvoicesTransactions: 'loadData',
    }),
  },
};
