export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Product',
    },
    cell: {
      type: 'text',
      value: (e) => e.inventoryProductName,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Category',
    },
    cell: {
      type: 'text',
      value: (e) => e.inventoryProductCategoryName,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'text',
      value: (e) => e.inventoryProductType,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'text',
      value: (e) => e.amount,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Quantity',
    },
    cell: {
      type: 'text',
      value: (e) => e.quantity,
    },
  },
];
