var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"fixed-header":"","height":"527px","show-extra":""},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.amount)+" ")]}},{key:"currency",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.currency)+" ")]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.created * 1000))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$utils.getTime(row.created * 1000)))])]}},{key:"confirmations",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.confirmations)+" / "+_vm._s(row.confirmationsMax)+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type)+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status)+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }