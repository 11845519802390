export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'slot',
      name: 'id',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'username',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Store',
    },
    cell: {
      type: 'slot',
      name: 'storename',
    },
  },
  {
    width: 15,
    header: {
      type: 'slot',
      name: 'statusHeader',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'slot',
      name: 'amount',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
];
