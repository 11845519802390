//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Extra from './Extra.vue';

import tableConfig from './tableConfig';

export default {
  props: {
    data: { type: Array, default: () => [] },
  },

  components: { Extra },

  data() {
    return {
      tableConfig,
    };
  },
};
