export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'slot',
      name: 'amount',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Confirmations',
    },
    cell: {
      type: 'slot',
      name: 'confirmations',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];
